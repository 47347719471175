@media only screen and (max-width: 750px) {
	html {
		font-size: 5px !important;
	}
}

@media only screen and (max-width: 350px) {
	html {
		font-size: 4px !important;
	}
}

.page2 {
	box-sizing: border-box;
	/* display: none; */
	background-image: url(@/assets/imgs/page2/bg.png);
	background-size: 100%;
	background-repeat: no-repeat;
	background-position-y: bottom;
	background-color: #030213;
	height: 100vh;
	/* overflow: hidden; */
}

.page2 .headerU {
	position: fixed;
	top: 0;
	z-index: 11;
	background: rgba(3, 2, 19, 0.9);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(10px);
}

.page2 .headerU .box-in {
	height: auto;
	padding: 3rem 20rem;
	overflow: visible;
}

/* .headerU .box-hover {
        transition: all 0.5s linear 0s;
        background: rgba(141, 123, 165, 0.10);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    } */

.page2 .headerU.active .box-hover {
	transform: translateY(-100%);
}

.page2 .headerU:hover .box-hover {
	transform: translateY(0);
}

.page2 .headerU .logo {
	width: 6.0185rem;
	height: 3.9381rem;
	flex-shrink: 0;
}

.page2 .headerU .navU a {
	color: #fff;
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0 1rem;
	position: relative;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;

	height: 3.6rem;
	padding: 0 2rem;
}

.page2 .headerU .navU a:hover {
	background: url("@/assets/imgs/nav-active.png") no-repeat;
	background-size: 100% 100%;
}

.page2 .headerU .navU a.active {
	background: url("@/assets/imgs/nav-active.png") no-repeat;
	background-size: 100% 100%;
}


/* .page2 *{
    box-sizing: border-box;
} */
.page2 .title {
	margin-top: 12.5%;
	height: 6.9rem;
	color: #FFF;
	text-align: center;
	font-family: Helvetica;
	font-size: 6rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.page2 .title-label {
	margin-top: 1.6rem;
	color: #FFF;
	text-align: center;
	text-overflow: ellipsis;
	font-family: Helvetica;
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 400;
	line-height: 3.2rem;
	/* 133.333% */
}


.page2 .form {
	width: 64rem;
	margin: 3.2rem auto 0;
	margin-top: 3.2rem;
}

.page2 .form .top {
	position: relative;
	display: flex;
	gap: 2rem;
}


.page2 .form .top .card {
	display: flex;
	align-items: center;
	gap: 2rem;
	padding: 2rem 4rem;
	width: 31rem;
	min-height: 10rem;
	background-size: 100% 100%;
}

.page2 .form .top .card.type1 {
	background-image: url(@/assets/imgs/page2/card_bg.png), url(@/assets/imgs/page2/card_border1.png);
	--color: #01F4CB;
}

.page2 .form .top .card.type2 {
	background-image: url(@/assets/imgs/page2/card_bg.png), url(@/assets/imgs/page2/card_border2.png);
	--color: rgb(96, 52, 155);
}

.page2 .form .top .card.type1 .ellipse img {
	width: 70%;
	margin: auto;
}

.page2 .form .top .card .ellipse {
	overflow: hidden;
	object-fit: contain;
	flex-shrink: 0;
	--size: 4rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	/* background: url(@/assets/page2/icon_game.jpg) no-repeat;
	background-size: 100% 100%; */
	background-color: var(--color);
}

.page2 .form .top .card .right .label {
	/* height: 1.8rem; */
	color: var(--color);
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	color: #fff;
	outline: none;
	border-radius: .5rem;
}

/* .page2 .form .top .type1 .right .label {
	background-color: #3B416D;
} */

.page2 .form .top .card .right .value {
	line-height: 2.8rem;
	color: #FFF;
	font-family: Helvetica;
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	word-break: break-all;
}

.page2 .form .top .icon {
	position: relative;
	--size: 4rem;
	width: var(--size);
	height: var(--size);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.page2 .form .top .icon:active {
	filter: brightness(.8);
}

.page2 .form .bottom .btn:active::after,
.page2 .form .bottom .swap:active::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	/* 半透明黑色 */
	pointer-events: none;
}

.page2 .form .bottom {
	margin-top: 2rem;
	padding: 3rem 2rem 2rem;
	width: 64rem;
	/* height: 34rem; */
	flex-shrink: 0;
	border-radius: 2rem;
	border: .15rem solid rgba(118, 126, 185, 0.50);

	background: rgba(48, 56, 84, 0.40);
}

.page2 .form .bottom .swap {
	position: relative;
	cursor: pointer;
	width: 7.3rem;
	height: 3.6rem;
	border-radius: 1rem;
	background: #3B416D;
	margin-left: 1.1rem;
	margin-right: .6rem;

	color: #FFF;
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.page2 .form .bottom .input1 .label {
	padding-left: 1rem;
	height: 1.8rem;
	color: #FFF;
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.page2 .form .bottom .input-box {
	margin-top: 1rem;
	display: flex;
	align-items: center;
	width: 60rem;
	height: 4.8rem;
	border-radius: 1rem;
	border: .1rem solid #484E77;
}

.page2 .form .bottom .input-box .prefix-icon {
	--size: 1.6rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	background-color: #5465E5;
	margin-left: 1rem;
}

.page2 .form .bottom .input-box .input {
	margin-left: 1.1rem;
	flex: 1;
	height: 100%;
	background-color: transparent;

	color: #FFF;
	font-family: Helvetica;
	font-size: 2rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.page2 .form .bottom .input-box .input::placeholder {
	color: rgba(255, 255, 255, 0.20);
}

.page2 .form .bottom .input-box .btn {
	position: relative;
	cursor: pointer;
	width: 7.3rem;
	height: 3.6rem;
	border-radius: 1rem;
	background: #3B416D;
	margin-left: 1.1rem;
	margin-right: .6rem;

	color: #FFF;
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.page2 .form .bottom .hr {
	margin-top: 2.8rem;
	--size: 3.2rem;
	height: var(--size);
	position: relative;
}

.page2 .form .bottom .hr .line {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: .1rem;
	background: #3B416D;
}

.page2 .form .bottom .hr .icon {
	position: absolute;
	top: 50%;
	left: 50%;
	width: var(--size);
	height: var(--size);
	transform: translate(-50%, -50%);
}

.page2 .form .bottom .amount {
	margin-top: 1.4rem;
}

.page2 .form .bottom .amount .label-box {
	padding-left: 1rem;
	display: flex;
	height: 1.8rem;
}

.page2 .form .bottom .amount .label-box .label {
	height: 1.8rem;
	color: #FFF;
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.page2 .form .bottom .amount .label-box .label1 {
	height: 1.8rem;
	color: rgba(255, 255, 255, 1);
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-left: 1.6rem;
}

.page2 .form .bottom .amount .value {
	padding-left: 1rem;
	height: 2.8rem;
	margin-top: 1.2rem;
	color: #FFF;
	font-family: Helvetica;
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background: linear-gradient(to right, rgb(98, 204, 237), rgb(80, 130, 190));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.page2 .form .submit-btn {
	cursor: pointer;
	margin-top: 3.4rem;
	width: 60rem;
	height: 4.8rem;
	position: relative;
	overflow: hidden;
}

.page2 .form .submit-btn w3m-button {
	width: 60rem !important;
	height: 4.8rem !important;
	display: flex;
	justify-content: center;
}

.page2 .form .submit-btn .btn {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	background: #03E0CF;

	color: #0C0D20;
	font-family: Helvetica;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page2 .SWAP_STATUS_ONGOING {
	pointer-events: all !important;
}

.page2 .SWAP_STATUS_ONGOING::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	cursor: no-drop;
}

.page2 .SWAP_STATUS_ONGOING::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 3rem;
	height: 3rem;
	margin-left: -1.5rem;
	margin-top: -1.5rem;
	background-image: url(@/assets/imgs/page2/load.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	pointer-events: none;
	z-index: 2;
	animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}